import { Box, Button, FormControl, IconButton, InputAdornment, Modal, OutlinedInput } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { getAccounts, getTransaction, patchAccountClearance } from "../../Components/Redux/Slice/Accounts/accounts";
import { openHandler } from "../../Components/Redux/Slice/common/overlaySlice";
import { dateFormate } from "../../Components/Common/dateFormate";
import { Controller, useForm } from "react-hook-form";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { CiSearch } from "react-icons/ci";
import ColumnSelection from "../../Components/Common/columnSelection";
import { useRemoteSort } from "../../Components/Common/sortHook";
import NoDataEmoji from "../../Components/Common/nodataEmoje";
import {TableStyles} from '../../Components/Common/tableStyle'
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Transactions from "./transactions";
import dayjs from "dayjs";

// function ActionCell( row ) {
//     // console.log(row);
//     const [openModal, setopenModal] = useState(false)
//     const handleOpenModal = ()=>setopenModal(true)
//     const handleCloseModal = ()=>setopenModal(false)
//     const [anchorEl, setAnchorEl] = useState(null);
//     const dispatch = useDispatch()
//     const open = Boolean(anchorEl);
//     const handleClick = (event) => {
//         setAnchorEl(event.currentTarget);
//     };
//     const handleClose = () => {
//         setAnchorEl(null);
//     };

//     let page = 1,
//         size = 10
   
//     return (
//         <div className='action-wrapper relative'>
//             <ListItemButton className='rounded-md' onClick={handleClick}><span><BiDotsHorizontalRounded className='text-xl' /></span></ListItemButton>
//             <Menu
//                 id="datatable-action-menu"
//                 aria-labelledby="datatable-action-button"
//                 anchorEl={anchorEl}
//                 open={open}
//                 onClose={handleClose}
//                 anchorOrigin={{
//                 vertical: 'top',
//                 horizontal: 'left',
//                 }}
//                 transformOrigin={{
//                 vertical: 'top',
//                 horizontal: 'left',
//                 }}
//                 PaperProps={{ style: { boxShadow: 'none',padding:0} }}
//                 className='shadow-md p-0'
//                 sx={{'& .css-6hp17o-MuiList-root-MuiMenu-list' : {padding:0}}}
//             >
//                 <div className='[&>li]:mb-2 [&>li>svg]:mr-2 [&>li>svg]:text-xl rounded-md overflow-hidden capitalize'>
//                     <MenuItem className='text-mainColor hover:bg-[#b3c0f742]' onClick={handleOpenModal}><FaEdit /> Edit</MenuItem>
//                 </div>
//             </Menu>

//         </div>
//     );
// }

const Accounts = () => {
    const {t,i18n} = useTranslation()
    const [open, setopen] = useState(false)
    const dispatch = useDispatch()
    const [activeRowData,setactiveRowData] = useState(false)
    const [page,setpage] = useState(1)
    const [size,setsize] = useState(10)
    // const [col,setcol] = useState(null)
    // const [dir,setdir] = useState('ASC')
    const [selectedDate, setSelectedDate] = useState(null);
    const {accounts} = useSelector(state=>state)
    const targetRef = useRef(null);
    const [searchValue, setsearchValue] = useState('')

    const form = useForm()
    const {register,control,handleSubmit,formState,setValue,reset} = form
    const {errors} = formState

    useEffect(() => {
        dispatch(getAccounts({page,size}))
    }, [dispatch])
    
    // console.log(accounts);

    //data table section
    const columns = [
        // {
        //     name: 'ID',
        //     selector: row => row.id,
        //     sortable: true,
        //     width:'120px'
        // },
        {
            id : 'accountHolderName',
            name: `${localStorage.getItem('lang') === 'ar' ? 'اسم مالك الحساب' : 'Account holder name'}`,
            selector: row => row?.accountHolderName,
            sortable: true,
        },
        {
            id : "bankAccountNumber",
            name: `${localStorage.getItem('lang') === 'ar' ? 'رقم الحساب' : 'Bank Account Number'}`,
            selector: row => row?.bankAccountNumber,
            sortable: false,
        },
        {
            id : "bankName",
            name: `${localStorage.getItem('lang') === 'ar' ? 'اسم البنك' : 'Bank Name'}`,
            selector: row => row?.bankName,
            sortable: false,
        },
        {
            id : "accountHolderType",
            name: `${localStorage.getItem('lang') === 'ar' ? 'نوع الحساب' : 'Account Holder Type'}`,
            selector: row => <span className="capitalize" data-tag="allowRowEvents">{localStorage.getItem('lang') === 'ar' ? row?.accountHolderTypeAr : row?.accountHolderType}</span>,
            sortable: false,
        },
        {
            id : "amountReceivable",
            name: `${localStorage.getItem('lang') === 'ar' ? 'القيمة الممكن استلامها' : 'Amount Receivable'}`,
            selector: row => <span data-tag="allowRowEvents">{row?.totalAmountReceivable} <span className="text-mainGreen" data-tag="allowRowEvents">{t('common.sar')}</span> </span>,
            sortable: false,
        },
        {
            id : "amountPayable",
            name: `${localStorage.getItem('lang') === 'ar' ? 'القيمة الممكن دفعها' : 'Amount Payable'}`,
            selector: row => <span data-tag="allowRowEvents">{row?.totalAmountPayable} <span data-tag="allowRowEvents" className="text-mainGreen">{t('common.sar')}</span></span>,
            sortable: false,
        },
        {
            id : "commReg",
            name: `${localStorage.getItem('lang') === 'ar' ? 'السجل التجارى' : 'Commerical Registeration Number'}`,
            selector: row => row?.commercialRegistrationNumber,
            sortable: false,
        },
        {
            id : "nid",
            name: `${localStorage.getItem('lang') === 'ar' ? 'الرقم القومى' : 'National ID'}`,
            selector: row => row.nationalId,
            sortable: false,
        },
        {
            id : "status",
            name: `${localStorage.getItem('lang') === 'ar' ? 'الحالة' : 'Status'}`,
            // selector: row => row?.status === 'PENDING' ? <span className="block min-w-[100px] text-center text-mainRed font-semibold px-2 py-1 rounded-md bg-opacity-10 bg-mainRed">{row?.status}</span> : <span className="text-mainGreen font-semibold px-2 py-1 rounded-md bg-opacity-10 bg-mainGreen min-w-[100px] text-center block">{row?.status}</span>,
            cell : row=> <span data-tag="allowRowEvents" className={`block min-w-[100px] font-semibold px-2 py-1 rounded-md bg-opacity-10 text-center capitalize ${row?.status === 'ACTIVE' ? 'text-mainGreen bg-mainGreen' : row?.status === 'DEACTIVATED' ? 'text-mainRed bg-mainRed' : row?.status === 'SUSPENDED' ? 'text-mainYellow bg-mainYellow' : ''}`}>{localStorage.getItem('lang') === 'ar' ? row?.statusAr : row?.status }</span>,
            sortable: true,
        },
        // {
        //     name: 'Actions',
        //     allowOverflow: true,
        //     button : true,
        //     cell: ActionCell
                
        // },
    ];
    
    const data = accounts?.accounts?.data

    const handleSearch = ()=>{
        dispatch(getAccounts({searchValue}))
    }

    const handleReset = ()=>{
        dispatch(getAccounts({page,size}))
        setsearchValue('')
    }

    const handlePageChange = page => {
        setpage(page);
    };
    const handleRowChange = (perPage) => {
        setsize(perPage);
    };

    useEffect(() => {
        //   console.log(searchValue);
        }, [searchValue])

    const [sortCount, setSortCount] = useState(0);

    // useEffect(() => {
    //     // console.log(size)
    //     dispatch(getAccounts({page,size,searchValue}))
    // }, [dispatch, page,size,searchValue])

    const {handleRemoteSort, icon} = useRemoteSort(getAccounts,dispatch,page,size,searchValue)


    // fields for transactions data-table
    const [transPage,settransPage] = useState(1)
    const [transSize,settransSize] = useState(10)
    const [id,setid] = useState('')
    const [resetPage,setresetPage] = useState(false)
    
    // this function used in accounts table to fetch transaction
    const handelRowClick = async (row)=>{
        setresetPage(true)
        setactiveRowData(row)
        setid(row?.id)
        let id = row?.id
        dispatch(getTransaction({id,transPage,transSize})).then( (e)=>{
            if(e?.payload?.message === 'SUCCESS'){
                settransPage(1)
                settransSize(10)
                if (targetRef.current) {
                    targetRef.current.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start', // Scroll to the top of the element
                        inline: 'nearest', // Scroll horizontally to the nearest edge
                    });
                }
            }
        } )
    }

    // const transColumns = [
    //     // {
    //     //     name: 'Order ID',
    //     //     selector: row => row.orderId,
    //     //     sortable: true,
    //     // },
    //     {
    //         name: `${localStorage.getItem('lang') === 'ar' ? 'المبلغ' : 'Amount'}`,
    //         cell: row =><><span className={`${row?.outward ? 'text-mainRed' : 'text-mainGreen'}`}>{row.transactionAmount}</span>&nbsp; <span> {t('common.sar')}</span></>,
    //         sortable: true,
    //     },
        
    //     {
    //         name: `${localStorage.getItem('lang') === 'ar' ? 'تاريخ الانشاء' : 'Created at'}`,
    //         selector: row => dateFormate(row.createdAt),
    //         sortable: true,
    //     },
    //     {
    //         name: `${localStorage.getItem('lang') === 'ar' ? 'الحالة' : 'status'}`,
    //         selector: row => <span className={`${row?.transactionStatus === 'COMPLETED' ? 'text-mainGreen' : row?.transactionStatus === 'PENDING' ? 'text-amber-400' : row?.transactionStatus === 'CANCELLED' ? 'text-mainRed' : row?.transactionStatus === 'ON_HOLD' ? 'text-mainYellow' : null} font-semibold`}>{row?.transactionStatus}</span>,
    //         sortable: true,
    //     },
    //     {
    //         name: `${localStorage.getItem('lang') === 'ar' ? 'رقم الطلب' : 'Order ID'}`,
    //         cell: row => <Button><Link to='/dashboard/orders/orderdetails' state={{id:row?.orderId}}>{row?.orderId}</Link></Button> ,
    //         sortable: true,
    //     },
    //     // {
    //     //     name: 'Actions',
    //     //     allowOverflow: true,
    //     //     button : true,
    //     //     cell: ActionCell
                
    //     // },
    // ];
    
    // const transData = accounts?.transactions?.data

    // const handleTransPageChange = page => {
    //     settransPage(page);
    // };
    // const handleTransactionRowChange = (perPage) => {
    //     settransSize(perPage);
    // };

    useEffect(() => {
        if(activeRowData){
            dispatch(getTransaction({id,transPage,transSize,selectedDate}))
        }
    }, [])

    // date range

    // const [date, setdate] = useState([
    //     {
    //         startDate: null,
    //         endDate: null,
    //         key: 'selection'
    //     }
    // ]);

    // let fromDate = new Date(date[0]?.startDate)
    // let toDate = new Date(date[0]?.endDate)

    // console.log(data);
    // const handleDateRangeChange = (e)=>{
    //     setdate([e.selection])
    //     let selectedDate = e?.selection
    //     setSelectedDate(selectedDate)
    //     dispatch(getTransaction({transPage,transSize,id,selectedDate}))
    //     // dispatch(getRatingSummary(selectedDate))
    // }

    const {dateOverlay}= useSelector(state=>state.overlay)

    const handleButton = ()=>{
        dispatch(openHandler({dateShow:!dateOverlay}))
    }

    // const {handleRemoteSort:transSort, defState:transDef} = useRemoteSort(getTransaction,dispatch,transPage,transSize,null,null,selectedDate,id)

    // Account Clearance Modal

    const style = {
        position: 'absolute',
        top: '45%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 'auto',
        minWidth : '500px',
        height: 'auto',
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 2,
        py: 4,
        px:2,
        borderRadius : 5,
    };
    
    const [openClearance, setOpenClearance] = useState(false);
    const [from, setfrom] = useState(null)
    const [to, setto] = useState(null)

    const handleClearanceOpen = (e) => {
        setOpenClearance(true)

    };
    const handleClearanceClose = () => {
        setOpenClearance(false);
        setfrom(null)
        setto(null)
        reset()
    }

    

    // useEffect(() => {
    //     let fromDateFormat = from ? new Date(Date.UTC(from.getFullYear(), from.getMonth(), from.getDate())).toISOString() : null;
    //     setValue('startDate',fromDateFormat)
    // }, [from])

    // useEffect(() => {
    //     let toDateFormat = to ? new Date(Date.UTC(to.getFullYear(), to.getMonth(), to.getDate())).toISOString() : null;
    //     setValue('endDate',toDateFormat)
    // }, [to])

    const submitHandler = async (values)=>{        
        dispatch(patchAccountClearance({id,values})).then( (e)=>{
            if(e?.payload?.message === "SUCCESS"){
                dispatch(getTransaction({transPage,transSize,id}))
                handleClearanceClose()
            } else {
                handleClearanceClose()
            }
        } )
    }

    
    // const [selectedColumns, setSelectedColumns] = useState(localStorage.getItem('lang') === 'ar' ? ['اسم مالك الحساب', 'رقم الحساب', 'اسم البنك', 'نوع الحساب', 'القيمة الممكن استلامها', 'القيمة الممكن دفعها', 'الحالة'] : ['Account holder name', 'Bank Account Number', 'Bank Name', 'Account Holder Type', 'Amount Receivable', 'Amount Payable', 'Status']);
    const [selectedColumns, setSelectedColumns] = useState(['accountHolderName', 'bankAccountNumber', 'bankName', 'accountHolderType', 'amountReceivable', 'amountPayable', 'status']);
    const [openMenu, setopenMenu] = useState(false)
    const [visibleColumns, setVisibleColumns] = useState(columns.filter((column) => selectedColumns.includes(column.id)));

    const handleOpenMenu = ()=>{
        setopenMenu(true)
    }
    const handleCloseMenu = ()=>{
        setopenMenu(false)
    }

    const handleVisibleColumnsChange = useCallback((newVisibleColumns) => {
        setVisibleColumns(newVisibleColumns);
    }, []);

    useEffect(() => {
        // setSelectedColumns(localStorage.getItem('lang') === 'ar' ? ['اسم مالك الحساب', 'رقم الحساب', 'اسم البنك', 'نوع الحساب', 'القيمة الممكن استلامها', 'القيمة الممكن دفعها', 'الحالة'] : ['Account holder name', 'Bank Account Number', 'Bank Name', 'Account Holder Type', 'Amount Receivable', 'Amount Payable', 'Status']);
        setSelectedColumns(['accountHolderName', 'bankAccountNumber', 'bankName', 'accountHolderType', 'amountReceivable', 'amountPayable', 'status']);
    }, []);
    
    useEffect(() => {
        // setVisibleColumns(columns.filter((column) => selectedColumns.includes(column.name)));
        setVisibleColumns(columns.filter((column) => selectedColumns.includes(column?.id)));
    }, [selectedColumns]);


    return ( 
        <article className="accounts-wrapper pb-5">
            <section className="order-title">
                <h1 className="text-[#333] font-bold text-3xl mb-4 font-playfair capitalize">{t('accounts.title')}</h1>
            </section>

            <section className={`couriers-control-wrapper flex flex-wrap ${localStorage.getItem('lang') === 'ar' ? 'flex-row-reverse' : 'flex-row'} justify-center lg:justify-end items-center mt-5 w-full`}>

                {/* <div className='flex gap-x-3'>
                    <div>
                        <Button
                            id="sort-dropdown"
                            aria-controls={openBy ? 'demo-customized-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={openBy ? 'true' : undefined}
                            variant="contained"
                            disableElevation
                            onClick={handleClickBy}
                            endIcon={<KeyboardArrowDownIcon />}
                            className='bg-[#fff] text-mainColor py-3 text-sm font-[600] capitalize'
                        >
                            {col ? col?.replace(/_/g, " ") : 'Sort By'}
                        </Button>
                        <Menu
                            id="demo-customized-menu"
                            MenuListProps={{
                            'aria-labelledby': 'sort-dropdown',
                            }}
                            anchorEl={anchorElBy}
                            open={openBy}
                            onClose={handleCloseBy}
                            className=' w-full capitalize'
                        >
                            <MenuItem disabled className='text-[#9b96a8] hover:bg-[#d9c8ff42] capitalize'>
                                Sort By
                            </MenuItem>

                            {sortByList?.map( (item,index)=>(
                                <MenuItem 
                                    key={index} 
                                    className='text-[#333] font-semibold hover:bg-[#d9c8ff42]'
                                    onClick={()=>handleActiveBy(item,index)}
                                    selected = {index === selectedByIndex}
                                >
                                    {item?.name?.replace(/_/g, " ")}
                                </MenuItem>
                            ) )}
                            
                        </Menu>
                    </div>

                    <div>
                        <Button
                            id="sort-dropdown"
                            aria-controls={openOrder ? 'demo-customized-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={openOrder ? 'true' : undefined}
                            variant="contained"
                            disableElevation
                            onClick={handleClickOrder}
                            endIcon={<KeyboardArrowDownIcon />}
                            className='bg-[#fff] text-mainColor py-3 text-sm font-semibold capitalize'
                        >
                            {dir ? dir : 'Sort Order'}
                        </Button>
                        <Menu
                            id="demo-customized-menu"
                            MenuListProps={{
                            'aria-labelledby': 'sort-dropdown',
                            }}
                            anchorEl={anchorElOrder}
                            open={openOrder}
                            onClose={handleCloseOrder}
                            className=' w-full capitalize'
                        >
                            <MenuItem disabled className='text-[#9b96a8] hover:bg-[#d9c8ff42]'>
                                Sort Order
                            </MenuItem>

                            {sortOrderList?.map( (item,index)=>(
                                <MenuItem 
                                    key={index} 
                                    className='text-[#333] hover:bg-[#d9c8ff42] font-semibold'
                                    onClick={()=>handleActiveOrder(item,index)}
                                    selected = {index === selectedOrderIndex}
                                >
                                    {item?.name}
                                </MenuItem>
                            ) )}
                            
                        </Menu>
                        
                    </div>
                </div> */}

                <div className={`couriers-search-wrapper flex items-center justify-end gap-x-3 ${localStorage.getItem('lang') === 'ar' ? 'flex-row-reverse' : 'flex-row'}`}>
                    <FormControl 
                        className="search-form [&>div>input]:py-[.6rem]"
                        sx={{ width: '35ch',backgroundColor:'white',borderRadius:'6px','& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input':{padding : '10px'}, '& fieldset': { borderColor: 'primary.main',border:'none' } }} variant="outlined">
                        <OutlinedInput
                            type="search"
                            id="outlined-adornment-search"
                            placeholder={`${localStorage.getItem('lang') === 'ar' ? 'ابحث في الحسابات...' : 'Search Accounts...'}`}
                            onChange={(e)=>{
                                setsearchValue(e.target.value.trim())
                                if (!e.target.value) {
                                    handleReset();
                                }}}
                            startAdornment={
                            <InputAdornment position="start">
                                
                                <IconButton
                                    aria-label="search"
                                    onClick={handleSearch}
                                    edge="start"
                                >
                                    <CiSearch />
                                </IconButton>
                            </InputAdornment>
                            }
                        />
                    </FormControl>

                    <div className="flex">
                        <ColumnSelection columns={columns} handleOpen={handleOpenMenu} defColumns={selectedColumns} onVisibleColumnsChange={handleVisibleColumnsChange} open={openMenu} close={handleCloseMenu} />
                        {/* <Button onClick={handleOpenMenu} className='capitalize text-white bg-mainColor py-2 px-2 pr-2 rounded-md'> <LuMousePointerClick className='w-7 h-7 m-auto' />select columns</Button> */}
                    </div>
                </div>
            </section>

            <section className="datatable-wrapper mt-5 bg-white rounded-md relative">
                {data?.length ===0 ? 
                        <div className='p-8'><NoDataEmoji /></div>
                    :
                    <>
                        <DataTable
                            direction={localStorage.getItem('lang') === 'ar' ? 'rtl' : 'ltr'}
                            columns={visibleColumns}
                            data={data}
                            pagination
                            paginationPerPage = {size}
                            paginationRowsPerPageOptions = {[10,50,100]}
                            paginationServer
                            paginationTotalRows={accounts?.accounts?.meta?.total}
                            onChangePage={handlePageChange}
                            onChangeRowsPerPage={handleRowChange}
                            customStyles={TableStyles}
                            highlightOnHover
                            // selectableRows
                            // selectableRowsHighlight
                            // onSelectedRowsChange={(e)=>console.log(e)}
                            onRowClicked={(row)=>handelRowClick(row)}
                            sortServer
                            sortIcon={icon}
                            onSort={handleRemoteSort}
                            // sortFunction={handleRemoteSort}
                            paginationComponentOptions={
                                {
                                    rowsPerPageText : t('common.paginationRowText')
                                }
                            }
                        />
                    </>
                }

                {accounts?.loading ?
                    <div className='absolute top-0 bottom-0 left-0 right-0 bg-[rgba(0,0,0,.1)] backdrop-blur-sm flex justify-center items-center'>
                        <p className='w-20 h-20 border-4 border-t-0 border-b-0 border-gray-500 rounded-full animate-spin'></p>
                    </div> : null}
            </section>

            <section className='accounts-preview-wrapper mt-14' ref={targetRef}>
                {activeRowData ? 
                    // <>
                    //     <div className='accounts-preview-title font-semibold mb-3 text-[#4b515e71] text-lg capitalize'>
                    //         <h1 className="text-2xl text-mainColor">{activeRowData?.accountHolderName}</h1>
                            
                    //         <div className={`flex items-center justify-between mt-3 ${localStorage.getItem('lang') === 'ar' ? 'flex-row-reverse' : 'flex-row'}`}>
                    //             <div className="flex items-center gap-x-2 capitalize">
                    //                 <h2>{t('accounts.transactions')}</h2>
                                
                    //                 <div className='date-wrapper relative w-fit'>
                    //                     <Button className='bg-white text-[#333] rounded-md flex items-center' onClick={handleButton}>
                    //                         {date[0]?.startDate && date[0]?.endDate ? new Date(fromDate.getTime() + (fromDate?.getTimezoneOffset() * -60000)).toISOString().slice(0,10) + " - " + new Date(toDate.getTime() + (toDate?.getTimezoneOffset() * -60000)).toISOString().slice(0,10) : t('orders.date_title')} <span className='ml-1'><FaAngleDown /></span>
                    //                     </Button>

                    //                     {dateOverlay ? 
                                    
                    //                         <div className={`absolute -top-10 z-[9999] bg-white ${localStorage?.getItem('lang') === 'ar' ? 'left-full' : 'top-full'}`} dir='ltr'>
                    //                             <DateRangePicker
                    //                                 onChange={handleDateRangeChange}
                    //                                 showSelectionPreview={true}
                    //                                 moveRangeOnFirstSelection={false}
                    //                                 months={1}
                    //                                 ranges={date}
                    //                                 direction="vertical"
                    //                             />
                    //                         </div> 
                    //                     : null}
                                        
                    //                 </div>

                    //             </div>

                    //             <div>
                    //                 <Button className="bg-[#e5edf5] capitalize text-sm px-3" onClick={handleClearanceOpen}>{t('accounts.clearance_btn')}</Button>
                    //             </div>
                    //         </div>
                    //     </div>

                    //     <div className="relative bg-white rounded-md"> 
                    //         {transData?.length ===0 ? 
                    //             <div className='p-8'><NoDataEmoji /></div>
                    //         :
                    //             <>
                    //                 <DataTable
                    //                     direction={localStorage.getItem('lang') === 'ar' ? 'rtl' : 'ltr'}
                    //                     columns={transColumns}
                    //                     data={transData}
                    //                     pagination
                    //                     paginationPerPage = {transSize}
                    //                     paginationRowsPerPageOptions = {[10,50,100]}
                    //                     paginationServer
                    //                     paginationTotalRows={accounts?.transactions?.meta?.total}
                    //                     paginationDefaultPage={1}
                    //                     paginationResetDefaultPage = {resetPage ? 1 : null}
                    //                     onChangePage={handleTransPageChange}
                    //                     onChangeRowsPerPage={handleTransactionRowChange}
                    //                     customStyles={TableStyles}
                    //                     highlightOnHover
                    //                     sortIcon={transDef === 0 ? <BsSortDown /> : <BsDot className="text-[1px] opacity-0" />}
                    //                     onSort={transSort}
                    //                 />

                    //             {accounts?.transLoading ?
                    //                 <div className='absolute top-0 bottom-0 left-0 right-0 bg-[rgba(0,0,0,.1)] backdrop-blur-sm flex justify-center items-center'>
                    //                     <p className='w-20 h-20 border-4 border-t-0 border-b-0 border-gray-500 rounded-full animate-spin'></p>
                    //                 </div> : null}
                    //             </>
                    //         }

                    //     </div> 
                    // </>
                    <Transactions key={activeRowData?.id} activeRowData={activeRowData} t={t} dispatch={dispatch} accounts={accounts} transPage={transPage} settransPage={settransPage} transSize={transSize} settransSize={settransSize} register={register} reset={reset} setValue={setValue} id={id} resetPage={resetPage} TableStyles={TableStyles} handleSubmit={handleSubmit} errors={errors} control={control} />

                    : null}
                    {/* 
                    <Modal
                        open={openClearance}
                        onClose={handleClearanceClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style} dir={localStorage.getItem('lang') === 'ar' ? 'rtl' : 'ltr'}>
                            <div className="flex justify-between items-center border-b pb-2 mb-3">
                                <h1 className="text-xl text-[#333] capitalize font-semibold">{t('accounts.clearance_btn')}</h1>
                            </div>

                            <form noValidate onSubmit={handleSubmit(submitHandler)}>
                                <div className=" [&>div>label]:capitalize [&>div>label]:text-[#333] [&>div>label]:text-sm [&>div>label]:block [&>div>label]:mb-2 [&>div>label]:font-semibold [&>div>input]:bg-[#f3f6f9] [&>div>input]:w-full [&>div>input]:p-3 [&>div>input]:focus:outline-slate-400 [&>div>input]:rounded-md [&>div>input]:text-mainColor [&>div>input]:mb-1">
                                    
                                    <div className="flex items-center justify-between gap-4 [&>div>label]:capitalize [&>div>label]:mb-2 [&>div>label]:block">

                                        <div>
                                            <label>{t('accounts.clearance.form.start_date')}</label>

                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <Controller
                                                    name="startDate"
                                                    control={control}
                                                    defaultValue={from}
                                                    rules={{required : t('common.required')}}
                                                    render={({ field }) => (
                                                        <MobileDatePicker
                                                            {...field}
                                                            className="w-full p-0"
                                                            sx={{ '& fieldset': { border: 0, outline: 0 } }}
                                                            id="startDate"
                                                            disablePast
                                                            value={dayjs(from)}
                                                            onChange={(e) => {
                                                                field?.onChange(dayjs(e)?.format('YYYY-MM-DDTHH:mm:ss.000[Z]'))
                                                                const selectedDate = dayjs(e);
                                                                setfrom(selectedDate);
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider>

                                            {errors?.startDate?.message ? <p className="capitalize text-sm text-danger">{errors?.startDate?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('accounts.clearance.form.start_date_helper')}</p>}
                                        </div>

                                        <div>
                                            <label>{t('accounts.clearance.form.end_date')}</label>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <MobileDatePicker
                                                    {...register('endDate',{
                                                        required:{
                                                            value : true,
                                                            message : t('common.required')
                                                        },
                                                    })} 
                                                    className="w-full"
                                                    id='endDate'
                                                    value={to}
                                                    sx={{'& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input' : {backgroundColor : '#f3f6f9'}}}
                                                    onChange={(e) => {
                                                        // console.log(e);
                                                        setto(e?.$d)
                                                    }}
                                                    // closeOnSelect
                                                    // name={name}
                                                    // onBlur={onBlur}
                                                    
                                                />
                                            </LocalizationProvider>

                                            {errors?.endDate?.message ? <p className="capitalize text-sm text-danger">{errors?.endDate?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('accounts.clearance.form.end_date_helper')}</p>}
                                        </div>

                                    </div>

                                    <div className="mt-3">
                                        <label>{t('accounts.clearance.form.notes')}</label>

                                        <textarea
                                            className="w-full  h-auto min-h-[90px] resize-none rounded-md bg-[#f3f6f9] focus:outline-slate-400 text-mainColor p-2"
                                            id="notes"
                                            
                                            {...register('notes',{
                                                required:{
                                                    value : true,
                                                    message : t('common.required')
                                                },
                                                minLength : {
                                                    value : 10,
                                                    message : t('common.min_length',{length : '10'})
                                                },
                                                maxLength : {
                                                    value : 500,
                                                    message : t('common.max_length',{length : '500'})
                                                },
                                            })}
    
                                            minLength={10}
                                            maxLength={500}
                                        />

                                        {errors?.notes?.message ? <p className="capitalize text-[14px] text-danger">{errors?.notes?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('accounts.clearance.form.notes_helper')}</p>}

                                    </div>
                                    
                                    <div className={`flex ${localStorage.getItem('lang') === 'ar' ? 'flex-row-reverse' : 'flex-row'} justify-end item-center gap-x-4 mt-6 mb-0 capitalize text-sm [&>button]:text-white [&>button]:rounded-lg [&>button]:px-4`}>
                                        <Button className="bg-gray-400 capitalize" onClick={handleClearanceClose}>{t('common.cancel_btn')}</Button>
                                        <Button className="capitalize bg-mainColor disabled:bg-gray-400 disabled:opacity-50 disabled:cursor-not-allowed" type="submit" disabled={accounts?.transLoading}>{t('common.submit')}</Button>
                                    </div>
                                </div>
                            </form>
                        </Box>
                    </Modal> */}
            </section>

        </article>
    );
}

export default Accounts;