import { Box, Button, Modal } from "@mui/material"
import { useForm } from "react-hook-form"
import { IoCloseCircleOutline } from "react-icons/io5";
import { useDispatch } from "react-redux"
import { editPackage, getAdminPackages } from "../../../Components/Redux/Slice/Packages/packages";
import { useEffect } from "react";
import {resetPage} from '../../../Components/Redux/Slice/ResetPagination/resetPagination'

const style = {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 850,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 3,
    p: 2,
    borderRadius : 5,
};

const EditPackageModal = ({open,close,t,data}) => {
    const id = data?.id

    const form = useForm({
        defaultValues : {
            packageName : data?.packageName,
            packageNameAr : data?.packageNameAr,
            discountValue : data?.discountValue,
            discountType : 'fixed',
            discountTypeAr : 'fixed',
            packageType : "delivery_fees_discount",
            packageTypeAr : "خصم_على_التوصيل",
        }
    })
    const {register,control,handleSubmit,formState,setValue,getValues,watch,reset} = form
    const {errors} = formState
    const dispatch = useDispatch()

    const handleClose = ()=>{
        close()
        reset()
    }

    useEffect(() => {
        if(id && open){
            const defaultValues = {
                packageName : data?.packageName,
                packageNameAr : data?.packageNameAr,
                discountValue : data?.discountValue,
                discountType : 'fixed',
                discountTypeAr : 'fixed',
                packageType : "delivery_fees_discount",
                packageTypeAr : "خصم_على_التوصيل",
            }

            reset(defaultValues)
        }
    }, [open, id, reset])
    

    const submitHandler = (values)=>{
        const page = 1,
            size = 10

        const data = {
            ...values,
            description : "",
            descriptionAr : "",
            discountType : 'fixed',
            discountTypeAr : 'fixed',
            packageType : "delivery_fees_discount",
            packageTypeAr : "خصم_على_التوصيل",
        }
        
        dispatch(editPackage(data)).then( (e)=>{
            if(e?.payload?.message === 'SUCCESS'){
                dispatch(getAdminPackages({page,size}))
                dispatch(resetPage());
                handleClose()
            }
        } )

        
    }

    return ( 
        <article className="add-package-modal-wrapper">
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="add-offer-modal-title"
                aria-describedby="add-offer-modal-description"
            >
                <Box
                    sx={style} 
                    className='w-[90%] sm:w-[70%] lg:w-[50%] max-w-[990px]'
                    dir={localStorage?.getItem('lang') === 'ar' ? 'rtl' : 'ltr'}
                >

                    <div className="flex justify-between items-center">
                        <h2 className="text-[#333333] text-lg font-bold mb-3 capitalize">{t('dp.editNewPackage')}</h2>

                        <div>
                            <Button color="error" className="text-2xl -mt-2" onClick={handleClose}><IoCloseCircleOutline /></Button>
                        </div>
                    </div>
                    
                    <form onSubmit={handleSubmit(submitHandler)} noValidate className="menu-modal-form-wrapper mt-5 ">
                        <div className="input-wrapper  [&>div]:mb-3 [&>div>p]:text-xs [&>div>p]:text-mainRed [&>div>label]:capitalize [&>div>label]:text-[#333333] [&>div>label]:text-sm [&>div>label]:block [&>div>label]:mb-1 [&>div>label]:font-semibold [&>div>input]:bg-[#f3f6f9] [&>div>input]:w-full [&>div>input]:p-2 [&>div>input]:focus:outline-slate-400 [&>div>input]:rounded-md [&>div>input]:text-mainColor [&>div>input]:mb-1">
                            <div>
                                <label>{t('dp.packNameEn')}</label>
                                <input type="text" {...register('packageName',{
                                    required : {
                                        value : true,
                                        message : t('common.required')
                                    }
                                })} />
                                {errors?.packageName && <p>{errors?.packageName?.message}</p>}
                            </div>

                            <div>
                                <label>{t('dp.packNameAr')}</label>
                                <input type="text" {...register('packageNameAr',{
                                    required : {
                                        value : true,
                                        message : t('common.required')
                                    }
                                })} />
                                {errors?.packageNameAr && <p>{errors?.packageNameAr?.message}</p>}
                            </div>

                            <div>
                                <label>{t('dp.discount')}</label>
                                <input type="text" {...register('discountValue',{
                                    required : {
                                        value : true,
                                        message : t('common.required')
                                    },
                                    pattern : {
                                        value: /^[0-9]*$/,
                                        message : t('common.onlyNumbers')
                                    }
                                })} />
                                {errors?.discountValue && <p>{errors?.discountValue?.message}</p>}
                            </div>
                        </div>

                        <div>
                            <div className={`add-new-package mt-4 text-right flex items-center gap-x-3 justify-start ${localStorage.getItem('lang') === 'ar' ? 'flex-row' : 'flex-row-reverse'} [&>button]:min-w-[140px] [&>button]:capitalize`}>
                                <Button type="submit"  className="disabled:opacity-50 disabled:cursor-not-allowed bg-mainColor text-white">{t('common.edit_btn')}</Button>
                                <Button onClick={handleClose} className="bg-transparent text-mainRed border border-solid border-mainRed hover:bg-mainRed hover:text-white">{t('common.cancel_btn')}</Button>
                            </div>
                        </div>
                    </form>
                </Box>
            </Modal>
        </article>
     );
}
 
export default EditPackageModal;